import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { FontAwesome, Entypo, Ionicons } from '@expo/vector-icons';
import { getIST } from '../utilities/Utility';

const LeadsCard = ({item,navigation}) => {
	return (
		<View>
			<TouchableOpacity
				style={{
					padding: 12,
					borderRadius: 8,
					shadowColor: 'gray',
					backgroundColor: 'white',
					shadowOffset: { width: 2, height: 4 },
					elevation: 5,
					shadowOpacity: 0.26,
					margin: 3,
				}}
				onPress={()=>{navigation.navigate('Device Activation',item)}}
			>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
					}}
				>
					<Text style={{ fontSize: 18, fontWeight: '500' }}>{item.name}</Text>
					<Text
						style={{
							fontSize: 16,
							color: item.status == 'New' ? '#034efc' : 'green',
							fontWeight: '500',
						}}
					>
						{item.status}
					</Text>
				</View>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginTop: 5,
					}}
				>
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						<FontAwesome name="mobile-phone" size={24} color={'#fc6b03'} />
						<Text
							style={{
								fontWeight: '600',
								color: 'gray',
								marginLeft: 4,
								fontSize: 14,
							}}
						>
							{item.phoneNumber}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						<Ionicons name="time-outline" size={22} color={'#f53861'} />
						<Text style={{ fontWeight: '600', color: 'gray', marginLeft: 4 }}>
							{getIST(item.createdAt)}
						</Text>
					</View>
				</View>
			</TouchableOpacity>
		</View>
	);
}

export default LeadsCard

const styles = StyleSheet.create({})