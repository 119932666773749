import { StyleSheet, Text, View, ActivityIndicator, Image } from 'react-native';
import React from 'react';

const Loader = () => {
	return (
		<View style={styles.main}>
			<View style={styles.Loadercss}>
				<ActivityIndicator size={'large'} color={'#04213c'} />
			
			</View>
		</View>
	);
};

export default Loader;

const styles = StyleSheet.create({
	main: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
	},
	Loadercss: {
		alignItems: 'center',
	
	},
});
