import {
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
	ActivityIndicator,
	Modal,
	Alert,
	Image,
	Platform,
	ScrollView,
	Button,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../Components/Loader';
import NoInternet from '../../Components/ErrorHandlers/NoInternet';
import NetworkUtils from '../../utilities/NetworkUtils';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from '../../Components/common/Header';
import ApiHandler from '../../API/ApiHandler';
import { Dropdown } from 'react-native-element-dropdown';
import { AntDesign } from '@expo/vector-icons';
const data = [
	{ label: '1 Month', value: '1' },
	{ label: '3 Months', value: '2' },
	{ label: '6 Months', value: '3' },
	{ label: '12 Months', value: '4' },
	{ label: '24 Months', value: '4' },
];


const DeviceActivation = ({ navigation,route }) => {

	const [loader, setLoader] = useState(false);
	const [internet, setInternet] = useState(false);
	const [profileUser, setProfileUser] = useState({});
	const [selectedDropDown,setSelectedDropDown]=useState({})

	const[errorNo,setErrorNo]=useState();
	const [inputData,setInputData]=useState({
		lead_name:"",
		sim_no:"",
		imei_no:"",
		vehicle_no:"",
		selected_plan:"",
		trackerUserID:"",
		id:''
	})


	const vehiclenumberref = useRef();
	const simnoref = useRef();
	const imeiref = useRef();


	const keyboardType = Platform.OS === 'ios' ? 'number-pad' : 'phone-pad';
	const loaderRef = useRef();
	loaderRef.current = loader;

	useEffect(() => {
		// checkConnection();
		getProfileData();
	}, []);
	useEffect(() => {
		const focusHandler = navigation.addListener('focus', () => {
			CheckLeadSelection();
		});
		return focusHandler;
	}, [navigation,route]);

	
	const CheckLeadSelection=()=>{
		if(route.params){
			// console.log(route.params)
			setInputData({
				...inputData,
				lead_name: route.params.name,
				id: route.params.id,
				trackerUserId: route.params.trackerUserId,
			});
		}else{
			console.log('no lead selected yet');
		}
	}

	const getProfileData = async () => {
		setLoader(true);
		
		let isConnected = await NetworkUtils.isNetworkAvailable();

		if (isConnected) {
			setInternet(true);
		} else {
			setInternet(false);
			setLoader(false);
		}
	
			const value = await AsyncStorage.getItem('user');

			let user = JSON.parse(value);
			console.log(user);
			setProfileUser(user);
			
		setLoader(false);
	};
	const checkConnection = async () => {
		let isConnected = await NetworkUtils.isNetworkAvailable();
		if (isConnected) {
			setInternet(true);
		} else {
			setInternet(false);
		}
		return isConnected;
	};

	const submitActivation=async ()=>{
		console.log(inputData);
		const demodata = {
			id: inputData.id,
			records: {
				Opportunity: {
					stage: 'Prospecting',
					leadSource: '',
					subscriptionPlan: inputData.selected_plan, // this
					deviceStatus: '',
					renewalType: '',
					renewalPromise: '',
					description: null,
					assignedUserId: null,
					assignedUserName: null,
					teamsIds: [],
					teamsNames: {},
					campaignId: null,
					campaignName: null,
					amountCurrency: 'INR',
					amount: 1,
					name: inputData.lead_name, //this
					trackerUserID: inputData.trackerUserId, //this
					probability: 10,
					vehicleNumber: inputData.vehicle_no, //this
					iMEINUMBER: inputData.imei_no, //this
					sIMNUMBER: inputData.sim_no, //this
					
				},
			},
		};
		console.log("post data",demodata);
		const response = await ApiHandler.activateDevice(profileUser,demodata);
		// console.log("response frome device Activation",response.data);
		if (response.status == 200) {
			Alert.alert('Alert', 'Device Activated Successfully', [
				{
					text: 'OK',
					onPress: () => {},
				},
			]);
		} else {
			Alert.alert('Alert', 'Problem While Activating Device', [
				{
					text: 'OK',
					onPress: () => {},
				},
			]);
		}
	}

	
	



	
	const validateinput = (num) => {
		if (inputData.lead_name.length == 0) {
				setErrorNo(1);
		}else if (inputData.sim_no.length == 0) {
				setErrorNo(2);
				simnoref.current.focus();
			} else if (inputData.imei_no.length == 0) {
				setErrorNo(3);
				imeiref.current.focus();
			} else if (inputData.vehicle_no.length == 0) {
				setErrorNo(4);
				vehiclenumberref.focus();
			} else {
				submitActivation();

				//
			
		}
	};


	const makerequest = async (detail) => {
		
		
		let connection = await checkConnection();
		if (connection) {
		
	};
}

	return (
		<View style={{ backgroundColor: 'white', flex: 1, paddingTop: 30 }}>
			{/* <Header /> */}
			{loader ? (
				<Loader />
			) : !internet ? (
				<NoInternet tryagain={getProfileData} />
			) : (
				<>
					<ScrollView>
						<View>
							<Header />
							<Text
								style={{
									fontSize: 22,
									alignSelf: 'center',
									fontWeight: '600',
									marginVertical: 20,
								}}
							>
								Customer Details
							</Text>

							<View style={[styles.formcontainer]}>
								<View>
									<View style={{ marginTop: 30 }}>
										<View style={styles.lableContainer}>
											<Text
												style={{
													fontWeight: '600',
													color: 'black',
												}}
											>
												Lead Name
											</Text>
										</View>

										<TouchableOpacity
											style={styles.inputContainer}
											onPress={() => navigation.navigate('Leads List')}
										>
											<TextInput
												value={inputData.lead_name}
												style={styles.textinput}
												placeholder="choose the Lead"
												keyboardType={keyboardType}
												editable={false}
												onChangeText={(e) => {
													setErrorNo(null);
												}}
											/>
										</TouchableOpacity>

										{errorNo === 1 ? (
											<View
												style={{
													position: 'absolute',
													bottom: -18,
													alignItems: 'center',
													width: '100%',
												}}
											>
												<Text
													style={{
														color: 'red',
														fontSize: 12,
														alignSelf: 'flex-end',
													}}
												>
													Please Select the Lead.
												</Text>
											</View>
										) : null}
									</View>

									<View style={{ marginTop: 30 }}>
										<View style={styles.lableContainer}>
											<Text
												style={{
													fontWeight: '600',
													color: 'black',
												}}
											>
												Sim No
											</Text>
										</View>

										<View style={styles.inputContainer}>
											<TextInput
												ref={simnoref}
												value={inputData.sim_no}
												style={styles.textinput}
												placeholder="Enter The Sim Number"
												keyboardType={keyboardType}
												onSubmitEditing={() => {
													imeiref.current.focus();
												}}
												onChangeText={(e) => {
													setInputData({
														...inputData,
														sim_no: e,
													});
													setErrorNo(null);
												}}
											/>
										</View>

										{errorNo === 2 ? (
											<View
												style={{
													position: 'absolute',
													bottom: -18,
													alignItems: 'center',
													width: '100%',
												}}
											>
												<Text
													style={{
														color: 'red',
														fontSize: 12,
														alignSelf: 'flex-end',
													}}
												>
													Please Enter Sim Number.
												</Text>
											</View>
										) : null}
									</View>

									<View style={{ marginTop: 30 }}>
										<View style={styles.lableContainer}>
											<Text
												style={{
													fontWeight: '600',
													color: 'black',
												}}
											>
												IMEI Number
											</Text>
										</View>
										<View style={styles.inputContainer}>
											<TextInput
												ref={imeiref}
												value={inputData.imei_no}
												style={styles.textinput}
												placeholder="Enter The Device IMEI"
												autoComplete="off"
												keyboardType={keyboardType}
												autoCorrect={false}
												onSubmitEditing={() => vehiclenumberref.current.focus()}
												onChangeText={(e) => {
													setInputData({
														...inputData,
														imei_no: e,
													});
													setErrorNo(null);
												}}
											/>
										</View>
										{errorNo === 3 ? (
											<View
												style={{
													position: 'absolute',
													bottom: -18,
													alignItems: 'center',
													width: '100%',
												}}
											>
												<Text
													style={{
														color: 'red',
														fontSize: 12,
														alignSelf: 'flex-end',
													}}
												>
													Please Enter Device IMEI Number.
												</Text>
											</View>
										) : null}
									</View>

									<View style={{ marginTop: 30 }}>
										<View style={styles.lableContainer}>
											<Text
												style={{
													fontWeight: '600',
													color: 'black',
												}}
											>
												Vehicle Reg No
											</Text>
										</View>

										<View style={styles.inputContainer}>
											<TextInput
												ref={vehiclenumberref}
												style={[
													styles.textinput,
													{ lineHeight: 20, textAlign: 'auto' },
												]}
												value={inputData.vehicle_no}
												autoCapitalize={'characters'}
												placeholder="Please Enter Your Vehicle No"
												onChangeText={(e) => {
													setInputData({
														...inputData,
														vehicle_no: e,
													});
													setErrorNo(null);
												}}
											/>
										</View>
										{errorNo === 4 ? (
											<View
												style={{
													position: 'absolute',
													bottom: -18,
													alignItems: 'center',
													width: '100%',
												}}
											>
												<Text
													style={{
														color: 'red',
														fontSize: 12,
														alignSelf: 'flex-end',
													}}
												>
													Please Enter Your vehicle No.
												</Text>
											</View>
										) : null}
									</View>

									<View style={{...styles.inputContainer,paddingVertical:8,marginTop:20}}>
										<Dropdown
											style={styles.dropdown}
											placeholderStyle={styles.placeholderStyle}
											selectedTextStyle={styles.selectedTextStyle}
											inputSearchStyle={styles.inputSearchStyle}
											iconStyle={styles.iconStyle}
											data={data}
											search
											maxHeight={300}
											labelField="label"
											valueField="value"
											placeholder="Select Your Plan"
											searchPlaceholder="Search..."
											value={selectedDropDown}
											onChange={(item) => {
												setInputData({
													...inputData,
													selected_plan: item.label,
												});
												setSelectedDropDown(item);
											}}
											renderLeftIcon={() => (
												<AntDesign
													style={styles.icon}
													color="black"
													name="Safety"
													size={20}
												/>
											)}
										/>
									</View>

									<View
										style={{
											flexDirection: 'row',
											justifyContent: 'flex-end',
											gap: 10,
										}}
									>
										<View>
											<TouchableOpacity
												style={styles.button}
												onPress={() => {
													validateinput(1);
												}}
											>
												<Text style={{ color: 'white' }}>Activate Device</Text>
											</TouchableOpacity>
										</View>
									</View>
								</View>
							</View>
						</View>
					</ScrollView>
				</>
			)}
		</View>
	);
};

export default DeviceActivation;

const styles = StyleSheet.create({
	formcontainer: {
		paddingLeft: 25,
		paddingBottom: 30,
		paddingRight: 25,
		marginHorizontal: 15,
		marginVertical: 15,
		shadowColor: 'black',
		backgroundColor: 'white',
		shadowOffset: { width: 2, height: 4 },
		elevation: 5,
		shadowOpacity: 0.26,
		shadowRadius: 6,
		borderRadius: 10,
		width: 345,
		alignSelf: 'center',
	},
	button: {
		paddingVertical: 7,
		paddingHorizontal: 15,
		backgroundColor: '#26b2d1',
		borderRadius: 4,
		alignSelf: 'flex-end',
		marginTop: 25,
	},

	// new css
	input_section: {
		marginHorizontal: 15,
		borderWidth: 1,
		borderColor: 'rgba(0,0,0,0.3)',
		borderRadius: 8,
		padding: 15,
	},
	lableContainer: {
		backgroundColor: 'white',
		alignSelf: 'flex-start',
		paddingHorizontal: 3,
		marginStart: 10,
		zIndex: 1,
		elevation: 1,
		shadowColor: 'white',
		position: 'absolute',
		top: -12,
	},
	inputContainer: {
		borderWidth: 1,
		borderRadius: 8,
		paddingLeft: 8,
		paddingVertical: 12,
		zIndex: 0,
		borderColor: 'rgba(0,0,0,0.3)',
	},
	textinput: {
		outlineStyle: 'none',
		color: 'black',
		fontWeight: '500',
	},
	dropdown: {
	paddingHorizontal:6,

	},
	icon: {
		marginRight: 5,
	},
	placeholderStyle: {
		fontSize: 16,
	},
	selectedTextStyle: {
		fontSize: 16,
	},
	iconStyle: {
		width: 20,
		height: 20,
	},
	inputSearchStyle: {
		height: 40,
		fontSize: 16,
	},
});
