import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import React from 'react';


const NoInternet = ({ tryagain }) => {
	console.log('tryagain', tryagain);
	return (
		<View style={{ backgroundColor: '#fff', flex: 1 }}>
			<View>
				<Image
					source={require('../../assets/images/connection-lost-illustration.png')}
					alt="connection-lost"
					style={{ height: 350, width: 'auto' }}
				/>
			</View>
			<View style={{ marginVertical: 15, marginHorizontal: 40 }}>
				<Text
					style={{
						textAlign: 'center',
						fontSize: 25,
						color: "black",
					}}
				>
					No Internet Connection Found
				</Text>
			</View>
			<View style={{ marginVertical: 5, marginHorizontal: 40 }}>
				<Text
					style={{
						textAlign: 'center',
						fontSize: 18,
						color: 'grey',
						lineHeight: 25,
					}}
				>
					Please check your internet connection and try again
				</Text>
			</View>
			<TouchableOpacity onPress={() => tryagain()}>
				<View
					style={{
						marginVertical: 30,
						backgroundColor: "black",
						width: 130,
						alignSelf: 'center',
						paddingVertical: 10,
						borderRadius: 20,
					}}
				>
					<Text style={{ textAlign: 'center', color: 'white', fontSize: 18 }}>
						Try Again
					</Text>
				</View>
			</TouchableOpacity>
		</View>
	);
};

export default NoInternet;

const styles = StyleSheet.create({});
