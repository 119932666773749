import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react';
import { Ionicons } from 'react-native-vector-icons';
import { useNavigation } from '@react-navigation/native';


const Header = () => {
	const navigation = useNavigation();
	return (
		<View style={styles.main}>
			<TouchableOpacity onPress={()=>navigation.goBack()}>
				<Ionicons name="arrow-back" size={25} />
			</TouchableOpacity>
			<Image
				source={require('../../assets/images/logo_full.png')}
				style={{ width: 200, height: 40 }}
			/>
		</View>
	);
}

export default Header

const styles = StyleSheet.create({
	main: {
		paddingHorizontal: 8,
		paddingVertical: 8,
		marginHorizontal:4,
		marginVertical:4,
		borderRadius: 6,
		alignItems: 'center',
		flexDirection:"row",
		justifyContent: 'space-between',
		shadowColor: 'gray',
		backgroundColor: 'white',
		shadowOffset: { width: 2, height: 4 },
		elevation: 5,
		shadowOpacity: 0.26,
	},
});