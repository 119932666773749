import { StatusBar } from 'expo-status-bar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useState, useRef, useEffect } from 'react';
import {
	StyleSheet,
	Text,
	View,
	Image,
	TextInput,
	Button,
	TouchableOpacity,
	Alert,
} from 'react-native';
import ApiHandler from '../../API/ApiHandler';
import { UserContext } from '../../../App';
import Loader from '../../Components/Loader';
import NoInternet from '../../Components/ErrorHandlers/NoInternet';
import NetworkUtils from '../../utilities/NetworkUtils';
export default function Login({ navigation }) {
	const [user, setUser] = useState({
		username: '',
		password: '',
	});
	const [errorNo, setErrorNo] = useState(null);
	const uservalidation = React.useContext(UserContext);
	const [internet, setInternet] = useState(true);
	const [loader, setLoader] = useState(false);
	const usernameref = useRef();
	const passwordref = useRef();
	const loaderRef = useRef();
	loaderRef.current = loader;

	useEffect(() => {
		checkConnection();
		usernameref.current.focus();

	}, []);

	const checkConnection = async () => {
		let isConnected = await NetworkUtils.isNetworkAvailable();
		if (isConnected) {
			setInternet(true);
		} else {
			setInternet(false);
			setLoader(false);
		}
		return isConnected;
	};

	const storeData = async (value) => {
		setLoader(true);
		
		let connection = await checkConnection();
		console.log(value);
		console.log('connection', connection);

		if (connection) {
			if (user.username.length === 0) {
				setErrorNo(1);
				usernameref.current.focus();
			} else if (user.password.length === 0) {
				setErrorNo(2);
				passwordref.current.focus();
			} else {
				const response = await ApiHandler.getUser(value);
				console.log(response);
				if (response.status === 200) {
					uservalidation.setValidUser(true);
					try {
						const jsonValue = JSON.stringify(value);
						await AsyncStorage.setItem('user', jsonValue);
						
					} catch {
						// saving error
						console.log('error while storing data');
					}
				} else {
					Alert.alert('Alert', 'Please Enter Valid Credentials', [
						{
							text: 'OK',
							onPress: () => {},
						},
					]);
				}
			}
		}

		setLoader(false);
	};

	const login = () => {
		storeData(user);
	};

	return (
		<>
			{loader ? (
				<Loader />
			) : !internet ? (
				<NoInternet tryagain={checkConnection} />
			) : (
				<View style={styles.container}>
					<View style={styles.imageContainer}>
						<Image
							style={styles.image}
							source={require('../../assets/images/logo_full.png')}
						/>
					</View>
				

					<View
						style={{
							padding: 15,
							borderRadius: 6,
							shadowColor: 'gray',
							shadowOpacity: 0.26,
							shadowOffset: { width: 0, height: 3 },
							shadowRadius: 10,
							elevation: 5,
							width: '85%',
							maxWidth: 500,
							marginHorizontal: 3,
						}}
					>
						<Text
							style={{
								alignSelf: 'center',
								fontWeight: '600',
								fontSize: 22,
								marginBottom: 15,
							}}
						>
							Login
						</Text>
						<View style={{ width: '100%', marginRight: 20 }}>
							<View style={styles.lableContainer}>
								<Text style={{ fontWeight: '600', color: 'gray' }}>
									User Name
								</Text>
							</View>
							<View style={styles.inputContainer}>
								<TextInput
									ref={usernameref}
									value={user.username}
									keyboardType="visible-password"
									autoComplete="off"
									autoCorrect={false}
									autoFocus={true}
									style={styles.textinput}
									placeholder="User Name"
									onSubmitEditing={() => passwordref.current.focus()}
									onChangeText={(e) => {
										setUser({ ...user, username: e });
										setErrorNo(null);
									}}
								/>
							</View>
							{errorNo === 1 ? (
								<View
									style={{
										position: 'absolute',
										bottom: -18,
										alignItems: 'center',
										width: '100%',
									}}
								>
									<Text
										style={{
											color: 'red',
											fontSize: 12,
											alignSelf: 'flex-end',
										}}
									>
										Please Enter Username
									</Text>
								</View>
							) : null}
						</View>

						<View style={{ marginVertical: 15 }}></View>

						<View style={{ width: '100%', marginRight: 20 }}>
							<View style={styles.lableContainer}>
								<Text style={{ fontWeight: '600', color: 'gray' }}>
									Password
								</Text>
							</View>
							<View style={styles.inputContainer}>
								<TextInput
									ref={passwordref}
									value={user.password}
									autoCorrect={false}
									autoComplete="off"
									secureTextEntry={true}
									style={styles.textinput}
									placeholder="Password"
									onSubmitEditing={() => login()}
									onChangeText={(e) => {
										setUser({ ...user, password: e });
										setErrorNo(null);
									}}
								/>
							</View>
							{errorNo === 2 ? (
								<View
									style={{
										position: 'absolute',
										bottom: -18,
										alignItems: 'center',
										width: '100%',
									}}
								>
									<Text
										style={{
											color: 'red',
											fontSize: 12,
											alignSelf: 'flex-end',
										}}
									>
										Please Enter Your Password
									</Text>
								</View>
							) : null}
						</View>

						<View style={{ marginVertical: 10 }}></View>
						<TouchableOpacity>
							<Text style={styles.forgot_button}>Forgot Password?</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={styles.loginBtn}
							onPress={() => {
								login();
							
							}}
						>
							<Text style={{ color: 'white' }}>LOGIN</Text>
						</TouchableOpacity>
					</View>
				</View>
			)}
		</>
	);
}
const styles = StyleSheet.create({
	container: {
		backgroundColor: '#fff',
		alignItems: 'center',
		backgroundColor: 'white',
		justifyContent: 'center',
		paddingTop:30 ,
		flex: 1,
	},

	image: {
		width: '100%',
		height: '100%',
		resizeMode: 'contain',
	},
	inputView: {
		width: '70%',
		// height: 50,
		marginBottom: 10,
	},
	TextInput: {
		width: '100%',
		// height: 55,
		// backgroundColor: '#F5F7F9',
		backgroundColor: 'white',
		flexDirection: 'row',
		// borderColor: 'rgba(0,0,0,0.2)',
		shadowOffset: { width: 3, height: 3 },
		elevation: 3,

		padding: 10,
		borderRadius: 10,
		alignItems: 'center',

		fontWeight: '600',
		marginTop: 5,
	},
	forgot_button: {
		height: 30,
		marginTop: 10,
	},
	loginBtn: {
		width: '100%',
		height: 45,
		marginBottom: 20,
		backgroundColor: '#0D4C92',
		shadowColor: 'gray',
		shadowOpacity: 0.26,
		shadowOffset: { width: 3, height: 2 },
		shadowRadius: 12,
		elevation: 3,
		padding: 10,
		borderRadius: 10,
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: '600',
		marginTop: 5,
	},
	imageContainer: {
		width: 300,
		height: 150,
	},
	// newcode
	lableContainer: {
		backgroundColor: 'white',
		alignSelf: 'flex-start',
		paddingHorizontal: 3,
		marginStart: 10,
		zIndex: 1,
		elevation: 1,
		shadowColor: 'white',
		position: 'absolute',
		top: -12,
	},
	inputContainer: {
		borderWidth: 1,
		borderRadius: 8,
		paddingLeft: 8,
		paddingTop: 15,
		paddingBottom: 15,
		zIndex: 0,
		borderColor: 'rgba(0,0,0,0.3)',
	},
	textinput: {
		outlineStyle: 'none' ,
		color: 'black',
		fontWeight: '500',
	},
});
