import AsyncStorage from "@react-native-async-storage/async-storage";

export const getIST = (date) => {
	let timestamp = new Date(date).valueOf();
	timestamp = timestamp + 5 * 3600000 + 1800000;
	let dateTime =
		new Date(timestamp).toLocaleDateString() +"  "+
		new Date(timestamp).toLocaleTimeString();
	return dateTime;
};
export const userStorage = async () => {
	try {
		const jsonValue = await AsyncStorage.getItem('user');
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
	}
};