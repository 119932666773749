import { NavigationContainer } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import AppNavigation from './src/Navigation/AppNavigation';
import React, { useEffect, useState } from 'react';
import AuthNavigation from './src/Navigation/AuthNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ApiHandler from './src/API/ApiHandler';
import Loader from './src/Components/Loader';

export const UserContext = React.createContext();
export default function App() {
const [validuser, setValidUser] = useState(false);
const [loader, setLoader] = useState(false);


useEffect(()=>{
checkLoginUser();
},[])

const checkLoginUser = async () => {
	console.log('useEffecet caaled');
  setLoader(true);
  const jsonValue = await AsyncStorage.getItem('user');
  let user= jsonValue != null ? JSON.parse(jsonValue) : null;
	
	if (user !== null) {
		userLogin(user);
	} else {
		setLoader(false);
	}

	console.log('user', user);
};

const userLogin = async (user) => {
	// setLoader(true);
	const response = await ApiHandler.getUser(user);
	if (response.status !== 200) {
		console.log('error while logging in');
    setValidUser(false);
	} else {
		setValidUser(true);
	}
  setLoader(false);
};

  return (
		<NavigationContainer>
			<UserContext.Provider value={{ setValidUser }}>
      {loader?<Loader />:validuser?<AppNavigation />:<AuthNavigation />}		
			</UserContext.Provider>
		</NavigationContainer>
	);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
