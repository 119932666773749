import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Login from '../Screens/AuthScreens/Login';

const AuthNavigation = () => {
	const Stack = createNativeStackNavigator();
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Home"
				component={Login}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
}

export default AuthNavigation

const styles = StyleSheet.create({})