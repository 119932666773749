import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from '../Screens/HomeScreens/Home';
import DeviceActivation from '../Screens/HomeScreens/DeviceActivation';
import LeadsList from '../Screens/HomeScreens/LeadsList';

const AppNavigation = () => {
	const Stack = createNativeStackNavigator();
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Home"
				component={Home}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Device Activation"
				component={DeviceActivation}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Leads List"
				component={LeadsList}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
}

export default AppNavigation

const styles = StyleSheet.create({})