import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react';
import{MaterialIcons,AntDesign} from '@expo/vector-icons';

const Home = ({navigation}) => {
	return (
		<View style={styles.maincontainer}>
			<TouchableOpacity style={styles.cardStyle}>
				<MaterialIcons
					name="sim-card"
					size={40}
					style={{ color: 'red', marginBottom: 10 }}
				/>
				<Text style={{ fontSize: 20, fontWeight: '600' }}>Sim Activation</Text>
			</TouchableOpacity>
			<TouchableOpacity
				style={styles.cardStyle}
				onPress={() => navigation.navigate('Device Activation')}
			>
				<AntDesign
					name="mobile1"
					size={40}
					style={{ color: '#4287f5', marginBottom: 10 }}
				/>
				<Text style={{ fontSize: 20, fontWeight: '600' }}>
					Device Activation
				</Text>
			</TouchableOpacity>
		</View>
	);
}

export default Home

const styles = StyleSheet.create({
	maincontainer: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: 30,
	},
	cardStyle: {
		backgroundColor: 'white',
		shadowColor: 'gray',
		alignItems: 'center',
		shadowOpacity: 0.26,
		shadowOffset: { width: 3, height: 2 },
		shadowRadius: 12,
		elevation: 3,
		padding: 20,
		borderRadius: 10,
		marginVertical: 20,
		minWidth: 250,
	},
});