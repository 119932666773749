import client from './Client';
import { decode as atob, encode as btoa } from 'base-64';

const userEncoder = (user) => {
	const credentials = btoa(user.username + ':' + user.password);
	const encodeddata = 'Basic ' + credentials;

	return {
		headers: {
			Authorization: encodeddata,
			'Content-Type': 'application/json',
		},
	};
};

const getUser = (value) => {

	var basicAuth = userEncoder(value);
	return client.get(
		'api/v1/App/user',
		{},
		basicAuth
	);
};
const getLeadsList = (user,offset,phoneNo) => {
	const basicAuth = userEncoder(user);
	if (phoneNo){
return client.get(
	'api/v1/Lead?&maxSize=20&offset=' +
		offset +
		'&orderBy=createdAt&order=desc&where[0][type]=textFilter&where[0][value]=' +
		phoneNo,
	{},
	basicAuth
);
	}else{
	return client.get(
		'api/v1/Lead?&maxSize=20&offset=' +
			offset +
			'&orderBy=createdAt&order=desc',
		{},
		basicAuth
	);
	}
	
};

const activateDevice = (user,data) => {
	var basicAuth = userEncoder(user);
	console.log('Credentials', data);
	return client.post('api/v1/Lead/action/convert',data, basicAuth);
};

export default {
	getUser,
	getLeadsList,
	activateDevice,
};


//select=salutationName,firstName,lastName,middleName,name,phoneNumberIsOptedOut,phoneNumber,phoneNumberData,status,emailAddressIsOptedOut,assignedUserId,assignedUserName,trackerUserID,createdAt
