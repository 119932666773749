import { FlatList, StyleSheet, Text, View, RefreshControl, TextInput, TouchableOpacity, Image } from 'react-native';
import React, { useEffect, useState } from 'react'
import Header from '../../Components/common/Header';
import { userStorage } from '../../utilities/Utility';
import ApiHandler from '../../API/ApiHandler';
import Loader from '../../Components/Loader';

import LeadsCard from '../../Components/LeadsCard';

const LeadsList = ({navigation}) => {
const [endReached, setEndReached] = useState(false);
const [isRefreshing, setIsRefreshing] = useState(false);
const [leadsList, setLeadsList] = useState([]);
const [offset, setOffset] = useState(0);
const [emptyLead, setEmptyLead] = useState(false);
const [loader, setLoader] = useState(true);
const [totalList, setTotalList] = useState('');
const [phoneNumber,setPhoneNumber]=useState('');
const [formatedPhone,setFormatedPhone]=useState('');
useEffect(() => {
	setLoader(true);
	getLeads(0, leadsList,'');
}, []);	

const getLeads = async (offset, updatedList,phoneNo) => {
	let user = await userStorage();
	const response = await ApiHandler.getLeadsList(user, offset,phoneNo);
	console.log('from Leads page', response);


	if (response.status == 200) {
		response.data.list && response.data.list.length == 0
			? setEmptyLead(true)
			: setEmptyLead(false);
		setLeadsList([...updatedList, ...response.data.list]);
		setTotalList(response.data.total);
// console.log("response object",response.data.list[0]);
		setOffset(offset + 20);
	} else {
		console.log('error while fetching leads List');
	}

	// console.log(response.data.list);
	setIsRefreshing(false);
	setLoader(false);
};
const handleRefresh = async () => {
		getLeads(0, [],'');
		setOffset(0);
};
const loadMoreData = async () => {
	console.log('loadmore... calling');
	if (!endReached && leadsList.length >= 20) {
		setEndReached(true);
		console.log('load more Leads list', leadsList.length);
		getLeads(offset, leadsList,'');
	}
};

const numberFormat = (text) => {
	let phoneNumber = text;
	let newNum = phoneNumber.replace(/[\(\ \)\-]/g, '');

	setPhoneNumber(newNum);
	var cleaned = ('' + text).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '',
			number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
				''
			);
		setFormatedPhone(number);

		return;
	}

	setFormatedPhone(text);
};
	return (
		<View style={{ flex: 1, backgroundColor: 'white', paddingTop: 30 }}>
			<Header />
			<>
				{loader ? (
					<Loader />
				) : (
					<>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								paddingHorizontal: 10,
								marginVertical: 6,
							}}
						>
							<TextInput
								placeholder="Please Search Here"
								// keyboardType="phone-pad"
								style={{
									flex: 1,
									alignItems: 'center',
									borderWidth: 1,
									borderColor: 'rgba(0,0,0,0.4)',
									borderRadius: 6,
									paddingLeft: 8,
									paddingVertical: 6,
								}}
								value={formatedPhone}
								onChangeText={(e) => {
									numberFormat(e);
								}}
							/>
							<TouchableOpacity
								// disabled={phoneNumber.length !==10}
								style={{
									...styles.button,
									backgroundColor:
										phoneNumber.length == 10 ? '#26b2d1' : 'grey',
								}}
								onPress={()=>getLeads(0,[],phoneNumber)}
							>
								<Text style={{ color: 'white' }}>Search</Text>
							</TouchableOpacity>
						</View>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								paddingHorizontal: 10,
								paddingVertical: 6,
							}}
						>
							{leadsList.length == 0
								? 'Total Leads (0)'
								: 'Total Leads (' + leadsList.length + ' of ' + totalList + ')'}
						</Text>
						{/* <ApiError
							responseCode={responseCode}
							setResponseCode={setResponseCode}
						/> */}
						{emptyLead ? (
							<FlatList
								data={['demo']}
								keyExtractor={(i, j) => j}
								showsVerticalScrollIndicator={false}
								renderItem={({ item }) => {
									return (
										<View
											style={{ alignItems: 'center', justifyContent: 'center' }}
										>
											<Image
												style={{ height: 360, width: 300, marginTop: 80 }}
												source={require('../../assets/images/No_Leads.png')}
											/>
											<Text
												style={{
													fontWeight: '600',
													fontSize: 16,
												}}
											>
												No Leads Found
											</Text>
										</View>
									);
								}}
								refreshControl={
									<RefreshControl
										refreshing={isRefreshing}
										onRefresh={() => {
											handleRefresh();
										}}
									/>
								}

								// ListFooterComponent={renderFooter}
							/>
						) : (
							<FlatList
								data={leadsList}
								keyExtractor={(i, j) => j}
								showsVerticalScrollIndicator={false}
								renderItem={({ item }) => {
									return <LeadsCard item={item} navigation={navigation} />;
								}}
								refreshControl={
									<RefreshControl
										refreshing={isRefreshing}
										onRefresh={() => {
											handleRefresh();
										}}
									/>
								}
								onEndReached={loadMoreData}
								onMomentumScrollBegin={() => {
									setEndReached(false);
								}}
								onEndReachedThreshold={0.1}
								// ListFooterComponent={renderFooter}
							/>
						)}
					</>
				)}
			</>
		</View>
	);
}

export default LeadsList

const styles = StyleSheet.create({
	button: {
		paddingVertical: 10,
		marginLeft:3,
		paddingHorizontal: 15,
		borderRadius: 4,
		alignSelf: 'flex-end',
		
	},
});